@font-face {
    font-family: "BebasNeue-Bold";
    src: url("../../assets/fonts/BebasNeue-Bold.ttf");
}

.desktop-footer{
    width: 100%;
    padding: 2rem;
    background-color: #222223;
    font-family: BebasNeue-Bold;
    color: white;

    .line1{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        h3{
            font-size: 1.9rem;
            margin: 0px;
            letter-spacing: 4px;
        }

        .socials-container{
            display: flex;

            .icon{
                width: 1.6rem;
                height: auto;
                color: white;
                margin-left: 10px;
            }
        }
    }

    .seperator{
        width: 100%;
        height: 0.15rem;
        border-radius: 10%;
        background-color: #DD1733;
        margin: auto;
        margin-bottom: 20px;
    }

    .menu-row{
        display: flex;

        .elem{
            font-size: 1.3rem;
            letter-spacing: 4px;
            margin: 0px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 991px){
    .desktop-footer{
        text-align: center;

        .line1{

            h3{
                font-size: 5vw;
            }

            .socials-container{

                .icon{
                    width: 5vw;
                }
            }
        }

        .seperator{
            margin-bottom: 10px;
        }

        .menu-row{
            flex-wrap: wrap;

            .elem{
                width: 33%;
                margin: 0px;
                font-size: 4vw;
                text-align: left;
            }
        }
    }
}