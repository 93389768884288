@font-face {
  font-family: BebasNeueRegular;
  src: url("../../assets/fonts/BebasNeue.otf");
}

.MuiPaper-root.MuiDrawer-paper {
  background: #141414;
}

.desktop {
  .header {
    display: flex;
    top: 1rem;
    width: 100%;
    background-color: #141414;
    margin: 0px;
    justify-content: center;
  }

  .headerHome {
    display: flex;
    position: relative;
    width: 100%;
    height: 40%;
    background-image: url("../../assets/carHeader.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .headerHomeText {
    padding-top: 4rem;
    padding-bottom: 10rem;
    font-family: BebasNeueRegular;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .headline {
      font-size: 5.5rem;
    }
    .seperator {
      background-color: #dd1733;
      height: 0.15rem;
      width: 100%;
      margin-top: -0.75rem;
      border-radius: 90px;
    }
    .subheader {
      font-size: 3rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .header-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .headerRow {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 1rem;
  }

  .headerText {
    cursor: pointer;
    font-family: BebasNeueRegular;
    color: white;
    font-size: 1.2rem;
  }

  .headerCols {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% / 7);
    .logo {
      height: 2.75rem;
      width: auto;
    }
  }

  .redBanner {
    height: 5rem;
    background-color: #dd1733;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .logo-container {
      height: 100%;
      width: calc(100% / 7);
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        height: 50%;
        width: auto;
      }
    }
  }
}

.drawer {
  width: 100%;
  height: 200rem;
  padding-top: 0.5rem;
  background-color: #141414;

  .bg1 {
    background: url("../../assets/homeImage1.png");
    background-repeat: no-repeat;
    background-position: 80% 80%;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .bg2 {
    background: url("../../assets/RALLYIMAGE3.png");
    background-repeat: no-repeat;
    background-position: 5% center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .bg3 {
    background: url("../../assets/pointsImage2.png");
    background-repeat: no-repeat;
    background-position: center 50%;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .bg4 {
    background: url("../../assets/pokerImage2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .bg5 {
    background: url("../../assets/artImage1.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .bg6 {
    background: url("../../assets/contactImage1.png");
    background-repeat: no-repeat;
    background-position: 0% center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .text-container {
    font-family: BebasNeueBold;
    margin-bottom: 0.5rem;

    .drawerText {
      color: white;
    }
  }

  .headline {
    color: white;
    font-family: BebasNeueBold;
    margin-left: 1.5rem;
    margin-top: 4rem;
  }

  .social-container {
    width: 100%;
    display: flex;

    .icon {
      width: 5rem;
      height: auto;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.redBanner-mobile {
  height: 5rem;
  background-color: #dd1733;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12rem;
  position: fixed;

  .logo-container {
    height: 100%;
    width: calc(100% / 7);
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-banner {
      height: 50%;
      width: auto;
    }
  }
}

.mobile {
  .container-normal {
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    background: rgba(1, 14, 31, 0.75);
    backdrop-filter: blur(2px);
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    position: fixed;
    z-index: 5;
  }

  .container-art {
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    background: rgba(34, 34, 35, 0.75);
    backdrop-filter: blur(2px);
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    position: fixed;
    z-index: 5;
  }

  .home {
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 5;
    background-image: url("../../assets/carHeader.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
  }

  .content {
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    color: white;
    font-family: BebasNeueBold;
    font-size: 5rem;
    text-align: center;
    padding-top: 2rem;
  }

  .section {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headline {
    color: white;
    font-size: 2.5rem;
    font-family: BebasNeueBold;
  }

  .logo {
    height: auto;
    width: 3.5rem;
    max-width: 45px;
    min-width: 30px;
    margin-top: 2rem;
  }
}
