@font-face{
  font-family: MuseoSans;
  src: url("./assets/fonts/MuseoSans_500.otf");
}

body{
  overflow-x: hidden;
}

body::-webkit-scrollbar{
  width: 0rem;
}

:root{
  font-size: 20px;
  font-family: MuseoSans;
}

@media screen and (max-width: 1200px){
  :root{
    font-size: 1.5vw;
  }
}