@font-face {
  font-family: BebasNeueBold;
  src: url("../assets/fonts/BebasNeue-Bold.ttf");
}

@font-face {
  font-family: MuseoSans;
  src: url("../assets/fonts/MuseoSans_500.otf");
}

.dialog-popup {
  left: 50%;
  top: 50%;
  position: fixed;
  width: 338px;
  height: 360px;
  background-color: #fff;
  color: #000;
  transition: all 250ms;
  overflow: auto;
  background: #141414;
  border-radius: 8px;
  transform: translate(-50%, -60%);
  z-index: 5;

  @media (max-width: 460px) {
    width: 250px;
  }

  .input-container {
    height: 40px;
    background-color: white;
    display: flex;
    justify-content: center;
    width: 90%;
  }

  .successfull-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

    &.subtitle {
      height: unset;
      margin-top: 6px;
    }
  }

  .subtitle-text {
    color: white;
    font-family: BebasNeueBold;
    font-size: 16px;
    text-align: center;
  }

  .successfull-text {
    color: white;
    font-family: BebasNeueBold;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    margin-right: 10px;
    margin-top: 4px;
  }

  .logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    flex-direction: column;

    .circular-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .password-text {
      color: white;
      display: flex;
      justify-content: center;
      font-size: 20px;
      margin-top: 16px;
      margin-bottom: 16px;
      font-family: BebasNeueBold;
    }

    .popup-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: 200px;
      min-width: 200px;
      width: 100%;

      &:hover {
        opacity: 0.75;
      }
    }

    .popup-button {
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #dd1733;
      padding: 2px 16px;
      transition: all 200ms;
      border-radius: 8px;
      cursor: pointer;
      font-family: BebasNeueBold;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;

      &.cancel {
        background: gray;
      }
    }

    .password-button-container {
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    input::placeholder {
      font-size: 16px;
      vertical-align: middle;
    }

    .password-input {
      border: none;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      color: #141414;
    }
  }
}

.mainFrame {
  width: 100vw;
  background-color: #141414;
  padding-top: 4rem;
  padding-bottom: 6rem;

  .pageContent {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1223px) {
  .mainFrame {
    min-height: calc(100vh - 80px);
    padding-top: 12rem;
  }
}

@media screen and (min-width: 1223px) {
  .two-square-row {
    min-height: 50vh;
  }
  .home-middle-panel {
    min-height: 30vh;
  }
  .rally-panel-container {
    min-height: 25vh;
  }
  .rally-phone-panel {
    min-height: 75vh;
  }
  .top-image {
    min-height: 40vh;
    min-width: 40vh;
  }
  .red-square {
    min-height: 40vh;
  }
  .white-panel {
    min-height: 25vh;
  }
  .white-square1 {
    min-height: 45vh;
  }
  .white-square2 {
    min-height: 55vh;
  }
  .points {
    min-height: 25vh;
  }
  .rating {
    min-height: 25vh;
  }
  .panel-container {
    min-height: 25vh;
  }
  .contact-panel {
    min-height: 55vh;
  }
  .text-panel {
    min-height: 25vh;
  }
  .panel-row {
    min-height: 35vh;
  }
}

.home-button-container {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;

    .popup-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: 300px;
      min-width: 300px;
      width: 100%;

      @media (max-width: 500px) {
        max-width: 200px;
        min-width: 200px;
      }
    }

    .popup-button {
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 16px;
      transition: all 200ms;
      border-radius: 8px;
      cursor: pointer;
      font-family: BebasNeueBold;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      background: #dd173175;
      margin-right: 20px;

      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 20px;
        height: 26px;
      }

      &.playstore {
        margin-right: unset !important;
      }

      .button-text {
        margin-top: 1px;
        margin-left: 10px;

        @media (max-width: 500px) {
          font-size: 10px;
        }
      }
    }
  }
}

.home {
  .two-square-row {
    width: 100%;
    height: 28.35rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .half-square {
      width: 49.75%;
      height: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .image1 {
        width: 100%;
        height: 100%;
        // background: url("../assets/homeImage1.png");
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: cover !important;
        object-fit: cover;
        object-position: center;
      }

      .image2 {
        width: 100%;
        height: 100%;
        // background: url("../assets/homeImage2.png");
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: cover !important;
        object-fit: cover;
        object-position: center;
      }

      .square-content {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 80%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 5%;
          width: 100%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 0.925rem;
        }
      }
    }
  }
  .home-middle-panel {
    height: 18.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      display: flex;
      flex-direction: column;
      width: calc(90%);
      height: 90%;

      .headline {
        font-family: BebasNeueBold;
        font-size: 3.5rem;
      }

      .seperator {
        height: 0.15rem;
        min-height: 0.15rem;
        width: 30%;
        border-radius: 90px;
        background-color: #dd1733;
        border-radius: 90px;
        margin-top: -0.5rem;
      }

      .lower-half {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80%;

        .row {
          display: flex;
          height: 50%;
          width: 100%;
          justify-content: space-around;
          align-items: center;

          .icon-container {
            margin-top: 1.5rem;
            height: 100%;
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              height: 100%;
              width: auto;
            }
          }

          .text-container {
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
          }

          .headline {
            font-family: BebasNeueBold;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.rally {
  .rally-panel-container {
    height: 14rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content1 {
      height: 100%;
      width: 100%;
      background: url("../assets/RALLYIMAGE1.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
        line-height: 5.5rem;

        .seperator {
          height: 0.15rem;
          min-height: 0.15rem;
          width: 100%;
          border-radius: 90px;
          background-color: #dd1733;
          border-radius: 90px;
          margin-bottom: 1rem;
        }
      }

      .row {
        display: flex;
        width: 20%;
        height: 10%;
        justify-content: center;
        align-items: center;

        .circle {
          height: 0.85rem;
          width: auto;
          margin-right: 1rem;
        }
        .text {
          color: white;
          font-family: MuseoSans;
          font-size: 0.85rem;

          a {
            color: white;
          }
        }
      }
    }

    .content2 {
      height: 100%;
      width: 100%;
      background: url("../assets/RALLYIMAGE2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
        line-height: 5.5rem;
      }

      .seperator {
        height: 0.15rem;
        min-height: 0.15rem;
        width: 100%;
        border-radius: 90px;
        background-color: #dd1733;
        border-radius: 90px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }

      .row {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 2.5rem;
        width: 80%;

        .logo-container {
          height: 100%;
          width: calc(100% / 7);
          display: flex;
          justify-content: center;
          align-items: center;

          .logo {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    .content3 {
      height: 100%;
      width: 100%;
      background: url("../assets/RALLYIMAGE4.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
      }
    }

    .content4 {
      height: 100%;
      width: 100%;
      background: url("../assets/RALLYIMAGE3.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
      }
    }

    .content5 {
      height: 100%;
      width: 100%;
      background: url("../assets/RALLYIMAGE5.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
      }
    }
  }

  .rally-phone-panel {
    height: 42.5rem;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      width: 90%;
      height: 95%;

      .rows {
        display: flex;
        height: 50%;
        width: 100%;

        .cols {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: calc(100% / 3);

          .image {
            height: 10rem;
            width: auto;
            margin-top: 1rem;
          }

          .headline {
            font-family: BebasNeueBold;
            font-size: 1.6rem;
            text-align: center;
            margin-top: 1rem;
          }

          .text {
            font-family: MuseoSans;
            font-size: 0.7rem;
            text-align: center;
            width: 60%;
          }
        }
      }
    }
  }
}

.app {
  .top-image {
    position: absolute;
    height: 27.5rem;
    width: 27.5rem;
    z-index: 2;
    align-self: center;
  }

  .red-square {
    margin-top: 13.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 22rem;
    width: 100%;
    background-color: #dd1733;
    margin-bottom: calc(80vw - 99.5%);

    .headline {
      position: absolute;
      color: white;
      font-family: BebasNeueBold;
      font-size: 12.5rem;
      line-height: 10rem;
    }
  }

  .two-square-row {
    width: 100%;
    height: 28.35rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .half-square {
      width: 49.75%;
      height: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .image1 {
        width: 100%;
        height: 100%;
        background: url("../assets/appImage1.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image2 {
        width: 100%;
        height: 100%;
        background: url("../assets/appImage3.png");
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: cover !important;
        object-fit: cover;
        object-position: center;
      }

      .square-content1 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 80%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 5%;
          width: 95%;
          font-family: MuseoSans;
          font-size: 1rem;
        }

        .app-row {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          justify-self: flex-end;
          width: 50%;
          height: 10%;
          bottom: 0;

          a {
            height: 100%;
          }

          .icon {
            height: 100%;
            width: auto;
            margin-right: 1.5rem;
          }
        }
      }

      .square-content2 {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 80%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 5%;
          width: 95%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 1rem;
        }
      }
    }
  }

  .white-panel {
    height: 14rem;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .row {
      display: flex;
      height: 50%;
      width: 90%;

      .cols {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: calc(100% / 6);
        align-items: center;
        animation: moveDown 0.25s ease-out;
        animation-fill-mode: forwards;

        .icon {
          margin-bottom: 2.5rem;
          height: 2.8rem;
          width: auto;
          box-shadow: 0px 0px 5px;
          -moz-box-shadow: 0px 0px 5px;
          border-radius: 190px;
          -moz-border-radius: 190px;
        }

        .text {
          font-family: BebasNeueBold;
          font-size: 1.4rem;
          text-align: center;
          line-height: 1.4rem;
        }
        &:hover {
          animation: moveUp 0.25s ease-out;
          animation-fill-mode: forwards;
        }
        @keyframes moveDown {
          0% {
            margin-top: -0.75rem;
          }
          100% {
            margin-top: 0px;
          }
        }
        @keyframes moveUp {
          0% {
            margin-top: 0px;
          }
          100% {
            margin-top: -0.75rem;
          }
        }
      }
    }
  }
}

.features {
  .top-image {
    position: absolute;
    height: 27.5rem;
    width: auto;
    z-index: 2;
    align-self: center;
    transform: translateX(1%);
  }

  .red-square {
    margin-top: 13.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 22rem;
    width: 100%;
    background-color: #dd1733;
    margin-bottom: calc(80vw - 99.5%);

    .headline {
      position: absolute;
      color: white;
      font-family: BebasNeueBold;
      font-size: 12.5rem;
      line-height: 10rem;
    }
  }

  .white-square1 {
    height: 24rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      width: 95%;
      height: 70%;
    }

    .headline {
      font-family: BebasNeueBold;
      font-size: 3.5rem;
      line-height: 3rem;
    }

    .seperator {
      width: 30%;
      border-radius: 90px;
      height: 0.15rem;
      min-height: 0.15rem;
      background-color: #dd1733;
      border-radius: 90px;
      margin-top: 1rem;
    }

    .infoText {
      margin-top: 1rem;
      width: 95%;
      height: 100%;
      font-family: MuseoSans;
      font-size: 1rem;
    }
  }

  .white-square2 {
    height: 30rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: calc(80vw - 99.5%);

    .phones {
      margin-top: 4rem;
      height: 13rem;
      width: auto;
    }

    .headline {
      margin-top: 2rem;
      font-family: BebasNeueBold;
      font-size: 1.6rem;
      line-height: 1.5rem;
      text-align: center;
    }

    .text {
      font-family: MuseoSans;
      width: 50%;
      margin-top: 1rem;
      font-size: 0.7rem;
      text-align: center;
    }

    .rows {
      width: 100%;
      display: flex;

      .cols {
        height: 100%;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .two-square-row {
    width: 100%;
    height: 28.35rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .half-square {
      width: 49.75%;
      height: 100%;
      display: flex;
      background-color: white;
      justify-content: center;
      align-items: center;

      .image1 {
        width: 100%;
        height: 100%;
        background: url("../assets/pokerImage3.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image2 {
        width: 100%;
        height: 100%;
        background: url("../assets/pokerImage2.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image3 {
        width: 100%;
        height: 100%;
        background: url("../assets/pointsImage2.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image4 {
        width: 100%;
        height: 100%;
        background: url("../assets/pointsImage3.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image5 {
        width: 100%;
        height: 100%;
        background: url("../assets/ratingImage1.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .image6 {
        width: 100%;
        height: 100%;
        background: url("../assets/ratingImage2.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }

      .content1 {
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 75%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 1rem;
          width: 95%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 1rem;
        }
      }

      .content2 {
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 75%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 1rem;
          width: 95%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 1rem;

          a {
            color: black;
          }
        }
      }

      .content3 {
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 75%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 1rem;
          width: 95%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 1rem;
        }
      }

      .content4 {
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 80%;

        .headline {
          font-family: BebasNeueBold;
          font-size: 3.5rem;
          line-height: 3rem;
        }

        .seperator {
          width: 75%;
          border-radius: 90px;
          height: 0.15rem;
          min-height: 0.15rem;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 1rem;
        }

        .infoText {
          margin-top: 1rem;
          width: 95%;
          height: 100%;
          font-family: MuseoSans;
          font-size: 1rem;
        }
      }
    }
  }

  .points {
    height: 13rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("../assets/pointsImage1.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;

      .headline {
        font-size: 12.5rem;
        font-family: BebasNeueBold;
        color: white;
        transform: translateY(4%);
      }
    }
  }

  .rating {
    height: 13rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("../assets/ratingbg.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;

      .headline {
        font-size: 12.5rem;
        font-family: BebasNeueBold;
        color: white;
        transform: translateY(4%);
      }
    }
  }

  .white-panel {
    height: 14rem;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .row {
      display: flex;
      height: 50%;
      width: 90%;

      .cols {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: calc(100% / 6);
        align-items: center;
        animation: moveDown 0.25s ease-out;
        animation-fill-mode: forwards;

        .icon {
          margin-bottom: 2.5rem;
          height: 2.8rem;
          width: auto;
          box-shadow: 0px 0px 5px;
          -moz-box-shadow: 0px 0px 5px;
          border-radius: 190px;
          -moz-border-radius: 190px;
        }

        .text {
          font-family: BebasNeueBold;
          font-size: 1.4rem;
          text-align: center;
          line-height: 1.4rem;
        }
        &:hover {
          animation: moveUp 0.25s ease-out;
          animation-fill-mode: forwards;
        }
        @keyframes moveDown {
          0% {
            margin-top: -0.75rem;
          }
          100% {
            margin-top: 0px;
          }
        }
        @keyframes moveUp {
          0% {
            margin-top: 0px;
          }
          100% {
            margin-top: -0.75rem;
          }
        }
      }
    }
  }
}

.art {
  .panel-container {
    height: 14rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content1 {
      height: 100%;
      width: 100%;
      background: url("../assets/artImage1.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        height: 70%;
        width: auto;
      }
    }

    .content2 {
      height: 100%;
      width: 100%;
      background: url("../assets/artImage2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .white-square1 {
    height: 18.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      width: 95%;
      height: 70%;
    }

    .headline {
      font-family: BebasNeueBold;
      font-size: 3.5rem;
      line-height: 3rem;
    }

    .seperator {
      width: 30%;
      border-radius: 90px;
      height: 0.15rem;
      min-height: 0.15rem;
      background-color: #e5007d;
      border-radius: 90px;
      margin-top: 1rem;
    }

    .infoText {
      margin-top: 1rem;
      width: 90%;
      height: 100%;
      font-family: MuseoSans;
      font-size: 1rem;
    }
  }

  .panel-row {
    height: 18.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(80vw - 99.5%);

    .panel {
      height: 100%;
      width: calc((100% / 3) - 0.75 * calc(80vw - 99.5%));
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      .shoe1 {
        height: 60%;
        width: auto;
      }
      .shoe2 {
        height: 60%;
        width: auto;
        transform: rotateZ(180deg);
      }
      .shoe3 {
        height: 60%;
        width: auto;
        transform: scaleX(-1);
      }
    }
  }
}

.contact {
  .panel-container {
    height: 14rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      height: 100%;
      width: 100%;
      background: url("../assets/contactImage1.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      object-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headline {
        color: white;
        font-family: BebasNeueBold;
        font-size: 5.5rem;
        line-height: 5.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .seperator {
          height: 0.15rem;
          min-height: 0.15rem;
          width: 100%;
          border-radius: 90px;
          background-color: #dd1733;
          border-radius: 90px;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }

      .text {
        color: white;
        font-family: MuseoSans;
        font-size: 0.85rem;
        line-height: 1rem;

        a {
          color: white;
        }
      }
    }
  }

  .text-panel {
    width: 100%;
    height: 15.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(80vw - 99.5%);

    .content {
      height: 100%;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 1rem;

      .headline {
        font-size: 2.25rem;
        font-family: BebasNeueBold;
        text-align: center;
      }

      .text {
        font-size: 1.35rem;
        font-family: MuseoSans;
        text-align: center;
        width: 70%;
      }
    }
  }

  .email-send-text {
    font-size: 1.35rem;
    font-family: MuseoSans;
    text-align: center;
    width: 70%;
    margin-bottom: 20px;
  }

  .contact-panel {
    height: 28.5rem;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .content {
      height: 90%;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .MuiInputBase-input {
        height: 10%;
      }

      .MuiOutlinedInput-input {
        padding: 1rem 10px;
      }

      .MuiInputLabel-outlined {
        transform: translate(0.75rem, 1rem);
      }

      .MuiInputLabel-shrink {
        transform: translate(12px, -0.4rem) scale(0.75);
        transform-origin: 0.75rem center;
      }

      .MuiInputBase-inputMultiline {
        height: 100% !important;
        padding: 0px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #141414;
        border-width: 1px;
      }

      .MuiButton-label {
        color: white;
      }

      .MuiButtonBase-root {
        background-color: #dd1733;
      }

      .MuiButton-contained {
        border: transparent;
      }

      .MuiFormLabel-root.Mui-focused {
        color: #141414;
      }

      .MuiOutlinedInput-multiline {
        padding: 14px 14px;
      }

      .submit-button {
        width: 5rem;
        margin-top: 5rem;
        outline: none !important;
      }

      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }

      .row-textfield {
        width: 49%;
      }

      .main-textfield {
        height: 10rem;
      }
    }
  }
}

.images {
  width: 100%;
  height: 100%;
}

.cover {
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.museo {
  font-family: MuseoSans;
}

.center {
  justify-content: center;
  align-items: center;
}

.homeMiddlePanelContent {
  background-color: white;
}

.homeMiddlePanelContentCols {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.rallyCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 10px;
  background-color: white;
}

.rallyColHeadline {
  font-family: BebasNeueBold;
  font-size: 2.8vw;
}

.rallyColText {
  font-family: MuseoSans;
  font-size: 1.3vw;
  text-align: center;
  margin: auto;
  width: 79%;
}

.rallyMiddlePanel1 {
  height: 1000px;
}

.appPanel1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 500px;
  width: 100%;
  background-color: #dd1733;
  margin-top: -20%;
}

.appPanel2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background-color: white;
}

.roundWidthShadow {
  box-shadow: 0px 0px 5px;
  -moz-box-shadow: 0px 0px 5px;
  border-radius: 190px;
  -moz-border-radius: 190px;
}

.submitButton:active {
  background-color: rgb(124, 13, 28);
}

@media screen and (max-width: 1500px) {
  .appPanel1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 400px;
    width: 100%;
    background-color: #dd1733;
    margin-top: -20%;
  }
}

.flexCol  {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

*:focus {
  outline: none;
}

.fab-button {
  position: fixed;
  background-color: white;
  color: black;
  bottom: 100;
  right: 20;

  &:hover {
    background-color: #ff3f90;
    color: white;
  }
}
